import React from 'react';
import { Header, Grid, Image, Button, Icon } from 'semantic-ui-react';
import { graphql } from "gatsby";
import Helmet from 'react-helmet';

import Layout from "../components/layout";

export default class extends React.Component {
  render() {
    const { airtable } = this.props.data;
    const { data } = airtable;
    const { title, description, thumbnail, link } = data;

    return (
      <Layout>
        <Helmet
          title={`Full Scope Web | ${title}`}
          meta={[
            { name: 'description', content: description.split('.')[0] },
          ]}
        />
        <div className="project-page">
          <Grid columns={2} style={{ margin: '0 !important' }} stackable>
            <Grid.Column>
              <Header
                className="project-page-header"
                as="a"
                href={link}
                target="_blank"
              >
                <Icon name="linkify" size="small" />
                <Header.Content>{title}</Header.Content>
              </Header>
              <div>
                {description.split("NEWLINE").map(paragraph => <p>{paragraph}</p>)}
              </div>
            </Grid.Column>
            <Grid.Column>
              <img className="project-page-image" src={thumbnail[0].url} />
            </Grid.Column>
          </Grid>
        </div>
      </Layout>
    )
  }
};

export const query = graphql`
  query($title: String!) {
    airtable(table: {eq: "Projects"}, data: {title: {eq: $title}}) {
      data {
        title
        description
        link
        thumbnail {
          url
        }
      }
    }
  }
`;